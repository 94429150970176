.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding-top: 20px;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    height: 30px;
    padding: 5px;
    border: 1px solid #1a192b;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.source {
    border-color: pink;
    background-color: pink;
    color: white;
}

.dndflow .dndnode.warehouse {
    border-color: #4181ED;
    background-color: #4181ED;
    color: white;
}

.dndflow .dndnode.lake {
    border-color: #38B9E7;
    background-color: #38B9E7;
    color: white;
}

.dndflow .dndnode.input {
    border-color: #2D2D2B;
    background-color: #2D2D2B;
    color: white;
}

.dndflow .dndnode.analysis {
    border-color: #B8926A;
    background-color: #B8926A;
    color: white;
}

.dndflow .dndnode.output {
    border-color: #B0B0B0;
    background-color: #B0B0B0;
    color: white;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 50rem;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }
    .dndflow aside {
        width: 20%;
        max-width: 250px;
    }
}