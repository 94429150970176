.purple {
    color: var(--imp-text-color) !important;
}

button:focus {
    box-shadow: none !important;
}


/* --------- */


/*  Preloader */


/* --------- */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: white;
    background-image: url(./Assets/pre.svg);
    background-repeat: no-repeat;
    background-position: center;
}

#preloader-none {
    opacity: 0;
}

#no-scroll {
    overflow: hidden;
    height: 100vh;
}




/* --------- */


/*Scrollbar   */


/* --------- */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: white;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 12px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: grey;
    border-radius: 12px;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* --------- */


/* Navbar Section  */


/* --------- */

.sticky {
    background-color: #c1c1c7a9 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 5px 5px 0px rgba(211, 211, 211, 0.15) !important;
    backdrop-filter: blur(15px) !important;
}

.sticky_bottom {
    background-color: white !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 5px 5px 0px rgba(211, 211, 211, 0.15) !important;
    backdrop-filter: blur(15px) !important;
}

.navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.navbar-toggler span {
    display: block !important;
    background-color: black !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}

@media (max-width: 767px) {
    .navbar {
        padding: 1rem 2rem !important;
        font-size: 1.4rem !important;
        background-color: #c1c1c7a9 !important;
        transition: all 0.3s ease-out 0s !important;
        box-shadow: 0px 10px 10px 0px rgba(228, 226, 232, 0.171) !important;
    }
    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

.navbar-brand {
    color: rgb(250, 250, 250) !important;
}

.logo {
    height: 1.4em !important;
    width: 2.5em !important;
}

.navbar-nav .nav-link {
    color: black !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
    .nav-link {
        padding: 0.7rem 1rem !important;
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: black;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}


/* --------- */


/* Home section */


/* --------- */

.wave {
    animation-name: wave-animation;
    /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s;
    /* Change to speed up or slow down */
    animation-iteration-count: infinite;
    /* Never stop waving :) */
    transform-origin: 70% 70%;
    /* Pivot around the bottom-left palm */
    display: inline-block;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.titles-container {
    align-self: center;
    text-align: center;
}

.titles-text {
    font-size: 40px;
    color: #7D7D7E;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(14deg);
    }
    /* The following five values can be played with to make the waving more or less extreme */
    20% {
        transform: rotate(-8deg);
    }
    30% {
        transform: rotate(14deg);
    }
    40% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0deg);
    }
    /* Reset for the last half to pause */
    100% {
        transform: rotate(0deg);
    }
}



#tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
}

.home-header {
    padding-top: 60px !important;
    align-content: center;
}

.home-name {
    align-content: center;
    color: black;
    font-size: 4em !important;
    padding-bottom: 2rem;
}

.home-title {
    align-content: center;
    color: black;
    font-size: 3em !important;
    padding-bottom: 2rem;
}

.home-section {
    position: relative;
    padding-bottom: 30px !important;
    padding-top: 20px !important;
    min-height: 65vh;
}

.home-content {
    padding: 8rem 0 6rem 0rem !important;
    color: whitesmoke;
    text-align: center;
}

.heading {
    font-size: 2.4em !important;
    padding-left: 50px !important;
}

.heading-name {
    font-size: 2.5em !important;
    padding-left: 45px !important;
}

.main-name {
    color: black;
}

.Typewriter__wrapper {
    font-size: 2.2em !important;
    color: black !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 2.4em !important;
    color: black !important;
}

@media (max-width: 767px) {
    .Typewriter__wrapper {
        font-size: 1.4em !important;
        font-weight: 500 !important;
        position: absolute !important;
    }
    .Typewriter__cursor {
        display: none !important;
    }
}

.myAvtar {
    justify-content: center !important;
    padding-top: 9em !important;
}

@media (max-width: 767px) {
    .myAvtar {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
}

.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}

.home-about-description {
    color: black !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
    border-color: white;
}

.home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: center;
}

.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.icon-colour {
    color: #700c86 !important;
}


/* --------- */


/* DWML */


/* --------- */

.dwml-ButtonGroup {
    padding-top: 30px !important;
    width: 100%;
}

.dwml-toggle-button {
    color: black !important;
    background-color: #EDEDED !important;
    border-color: #EDEDED !important;
}

.dwml-toggle-button:hover {
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
}

.dwml-toggle-button:checked {
    transform: scale(1.01) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgb(148, 148, 148) !important;
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
}

.dwml-repo-button {
    color: black !important;
    background-color: #EDEDED !important;
    border-color: #EDEDED !important;
    width: 90%;
    border-radius: 40;
}

.dwml-repo-button:hover {
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
}

.dwml-close-button {
    color: black !important;
    background-color: #EDEDED !important;
    border-color: #EDEDED !important;
    width: 100%;
    border-radius: 40;
}

.dwml-close-button:hover {
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* --------- */


/* Footer */


/* --------- */

.footer {
    background-color: white;
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
}

.footer-copywright {
    text-align: center !important;
}

.footer-body {
    z-index: 1;
    text-align: center !important;
}

@media (max-width: 767px) {
    .footer-copywright {
        text-align: center !important;
    }
    .footer-body {
        text-align: center !important;
    }
}

.footer h3 {
    font-size: 1em;
    color: black !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
}

.blockquote-footer {
    color: black !important;
}


/* --------- */


/* Projects */


/* --------- */

.project-section {
    position: relative !important;
    padding-top: 9vh !important;
    padding-bottom: 60px !important;
    background-color: white;
    min-height: 100vh;
}

.project-card {
    border-radius: 30px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    height: auto !important;
}

.project-card-view {
    border-radius: 50px !important;
    color: black !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
    overflow: hidden !important;
}

.project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 1px 1px 1px rgb(148, 148, 148) !important;
}

.card-img-top {
    opacity: 1 !important;
    border-radius: 30px !important;
    height: 16rem;
}

.project-btn-primary {
    color: black !important;
    background-color: #EDEDED !important;
    border-color: #EDEDED !important;
    overflow: hidden ;
}

.project-btn-primary:hover {
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
}

.project-btn-primary:focus {
    outline: none !important;
    box-shadow: none !important;
}

.project-heading {
    color: black !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
}

/* Chat custom css */

.cs-message--incoming .cs-message__content {
    background-color: #6dd66d !important;
}

.cs-message--incoming .cs-message__html-content {

    color: 'white';
}

.cs-message--outgoing .cs-message__content {
    background-color: rgb(196, 196, 196) !important;
}

.cs-message-input__content-editor-wrapper{
    background-color: rgb(226, 224, 224) !important;

}

.cs-message-input__content-editor{
    background-color: rgb(226, 224, 224) !important;

}

.cs-message-list__typing-indicator-container{
    color:#7D7D7E
}

.ps__thumb-y{
    color:#7D7D7E
}

/* custom product css */

.added-to-cart {
    background-color: #4caf50; /* Change to desired background color for "added to cart" state */
    transition: background-color 0.3s ease;
  }
  
  .added-to-cart svg {
    color: #fff; /* Change to desired color for "added to cart" state */
    transform: rotate(360deg) scale(1.2);
    transition: transform 0.3s ease;
  }


/* --------- */

@tailwind base;
@tailwind components;
@tailwind utilities;